import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Flex, Image, Text, Button } from "@chakra-ui/react";
import founder_pic from "./../images/founder_pic.svg";

export const AboutUs = () => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.aboutUs);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.aboutUs);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-around"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "5%" : "50px"}
      pt={isMobile ? "12%" : "50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "24px" : "60px"}
          color={"#AF6534"}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "32px" : "44px"}
          color={theme_color_black}
        >
          {translations.tagline2}
        </Text>
      </Flex>
      <Flex h="20px"></Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"center"}
        pr={isMobile ? "20px" : "50px"}
        pl={isMobile ? "20px" : "50px"}
      >
        <Flex justify={"center"} w={isMobile ? "100%" : "50%"} align={"start"}>
          <Image src={founder_pic} align={"center"} borderRadius="lg" />
        </Flex>
        <Flex
          flexDir={"column"}
          justifyContent={"space-around"}
          w={isMobile ? "100%" : "50%"}
          pl={isMobile ? "0px" : "50px"}
          pt={isMobile ? "5%" : "0px"}
        >
          <Flex justifyContent={"start"} w={"100%"} align={"start"} pb={"10px"}>
            <Text
              align={isMobile ? "center" : "start"}
              className="font-link-OpenSans"
              fontSize={isMobile ? "21px" : "43px"}
              color={theme_color_black}
            >
              {translations.message1}
            </Text>
          </Flex>
          <Flex flexDir="column" justifyContent={"start"} w={"100%"} align={"center"}>
            <Text
              align={"justify"}
              className="font-link-OpenSans"
              fontSize={isMobile ? "16px" : "27px"}
              color={theme_color_black}
              noOfLines={isExpanded ? null : isMobile ? 7 : 11}
            >
              {translations.message2}
            </Text>
            <Button onClick={toggleExpand} variant="link" mt={2} color={"#AF6534"} fontSize={isMobile ? "18px" : "27px"} >
              {isExpanded ? "View Less" : "View More"}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
