import { Flex } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TopNavBar } from "./topNavBar/topNavBar";
import { LandingPage } from "./landingPage";
import { NoPage } from "./screens/noPage";

export const LandingPageRouting = ({ isMobile }) => {
  const featureRef = useRef(null);
  const reviewRef = useRef(null);
  const locationRef = useRef(null);
  const contactUsRef = useRef(null);
  const homeRef = useRef(null);

  // const mainScrollContainerRef = useRef(null);
  // const ScrollToTop = ({ mainScrollContainerRef }) => {
  //   const location = useLocation();

  //   useEffect(() => {
  //     if (mainScrollContainerRef.current) {
  //       mainScrollContainerRef.current.scrollTo(0, 0);
  //     }
  //   }, [location, mainScrollContainerRef]);

  //   return null;
  // };
  return (
    <Flex flexDir={"column"} h={window.innerHeight * 0.999}>
      <Flex h={isMobile ? "10%" : "10%"} flex="1" />
      <Flex
        h={isMobile ? "85%" : "90%"}
        overflowY={"scroll"}
        css={{
          "&::-webkit-scrollbar": {
            width: "5px",
            height: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#AF6534",
          },
          "&::-webkit-scrollbar-track": {
            background: "#AF653470",
            borderRadius: "10px",
          },
        }}
        // ref={mainScrollContainerRef}
      >
        {/* <ScrollToTop mainScrollContainerRef={mainScrollContainerRef} /> */}
        <Routes>
          <Route
            path="/"
            element={
              <TopNavBar
                featureRef={featureRef}
                reviewRef={reviewRef}
                locationRef={locationRef}
                contactUsRef={contactUsRef}
                homeRef={homeRef}
              />
            }
          >
            <Route
              index
              element={
                <LandingPage
                  featureRef={featureRef}
                  reviewRef={reviewRef}
                  locationRef={locationRef}
                  contactUsRef={contactUsRef}
                  homeRef={homeRef}
                />
              }
            />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Flex>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </Flex>
  );
};
