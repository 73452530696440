import superhost from "./../src/landingPage/images/superhost.svg";
import profile_pic from "./../src/landingPage/images/profile_pic.jpg";
import air_rider_indoor_sky_diving from "./../src/landingPage/images/placesAroundImages/air_rider_indoor_sky_diving.png";
import breakout from "./../src/landingPage/images/placesAroundImages/breakout.png";
import camp from "./../src/landingPage/images/placesAroundImages/camp.png";
import desa_park_city from "./../src/landingPage/images/placesAroundImages/desa_park_city.png";
import flowrider from "./../src/landingPage/images/placesAroundImages/flowrider.png";
import game_on from "./../src/landingPage/images/placesAroundImages/game_on.png";
import go_kart from "./../src/landingPage/images/placesAroundImages/go_kart.png";
import ikea from "./../src/landingPage/images/placesAroundImages/ikea.png";
import kidzania from "./../src/landingPage/images/placesAroundImages/kidzania.png";
import kota_damansara_forest from "./../src/landingPage/images/placesAroundImages/kota_damansara_forest.png";
import nextgen from "./../src/landingPage/images/placesAroundImages/nextgen.png";
import restaurants from "./../src/landingPage/images/placesAroundImages/restaurants.png";
import secret_garden from "./../src/landingPage/images/placesAroundImages/secret_garden.png";
import treetop_adventure from "./../src/landingPage/images/placesAroundImages/treetop_adventure.png";
import tropicana_golf from "./../src/landingPage/images/placesAroundImages/tropicana_golf.png";
import vrfunn from "./../src/landingPage/images/placesAroundImages/vrfunn.png";
import air_rider_indoor_sky_diving_mobile from "./../src/landingPage/images/placesAroundImages/air_rider_indoor_sky_diving_mobile.jpg";
import breakout_mobile from "./../src/landingPage/images/placesAroundImages/breakout_mobile.jpg";
import camp_mobile from "./../src/landingPage/images/placesAroundImages/camp_mobile.jpg";
import desa_park_city_mobile from "./../src/landingPage/images/placesAroundImages/desa_park_city_mobile.jpg";
import flowrider_mobile from "./../src/landingPage/images/placesAroundImages/flowrider_mobile.jpg";
import game_on_mobile from "./../src/landingPage/images/placesAroundImages/game_on_mobile.jpg";
import go_kart_mobile from "./../src/landingPage/images/placesAroundImages/go_kart_mobile.jpeg";
import ikea_mobile from "./../src/landingPage/images/placesAroundImages/ikea_mobile.jpg";
import kidzania_mobile from "./../src/landingPage/images/placesAroundImages/kidzania_mobile.png";
import kota_damansara_forest_mobile from "./../src/landingPage/images/placesAroundImages/kota_damansara_forest_mobile.jpg";
import nextgen_mobile from "./../src/landingPage/images/placesAroundImages/nextgen_mobile.jpg";
import restaurants_mobile from "./../src/landingPage/images/placesAroundImages/restaurants_mobile.jpg";
import secret_garden_mobile from "./../src/landingPage/images/placesAroundImages/secret_garden_mobile.webp";
import treetop_adventure_mobile from "./../src/landingPage/images/placesAroundImages/treetop_adventure_mobile.jpg";
import tropicana_golf_mobile from "./../src/landingPage/images/placesAroundImages/tropicana_golf_mobile.jpg";
import vrfunn_mobile from "./../src/landingPage/images/placesAroundImages/vrfunn_mobile.jpg";

import reviewer1 from "./../src/landingPage/images/reviewImages/reviewer1.png";
import reviewer2 from "./../src/landingPage/images/reviewImages/reviewer2.png";
import reviewer3 from "./../src/landingPage/images/reviewImages/reviewer3.png";
import reviewer4 from "./../src/landingPage/images/reviewImages/reviewer4.png";
import reviewer5 from "./../src/landingPage/images/reviewImages/reviewer5.png";
import reviewer6 from "./../src/landingPage/images/reviewImages/reviewer6.png";
import reviewer7 from "./../src/landingPage/images/reviewImages/reviewer7.png";
import reviewer8 from "./../src/landingPage/images/reviewImages/reviewer8.png";
import reviewer9 from "./../src/landingPage/images/reviewImages/reviewer9.png";
import reviewer10 from "./../src/landingPage/images/reviewImages/reviewer10.png";

//color
export const theme_color_blue_light = "#A2D5EE";
export const theme_color_red = "#CB0413";
export const theme_color_white = "#FFFFFF";
export const theme_color_blue = "#1736A8";
export const theme_color_black = "#000000";
export const theme_color_green = "#60AC07";
export const theme_color_orange = "#FA8701";
export const theme_color_blue_pale = "#D7E3E9";
export const theme_color_peach_pale = "#E7E4E4";
export const theme_color_blue_sky = "#EFF5F7";

//fontsizes
export const topNav_font_size_wide = "20px";
export const topNav_font_size_narrow = "18px";
export const topNav_font_size_mobile = "17px";

//padding
export const topNav_padding_wide = "17px";
export const topNav_padding_narrow = "7px";
export const topNav_padding_mobile = "5px";

//review list
export const reviewList = [
  {
    image: reviewer1,
    rating: "5",
    review:
      "Great place to stay in. Provided good sound system. Great place to hang out. Got pool table. Once you in, there is no need to get out of the unit for activity. Door to door food delivery. Very pro active n cool host. Best stay. Highly recommended. Can book without a purpose. Just book n chill.",
    guestName: "Melvin",
  },
  {
    image: reviewer2,
    rating: "5",
    review:
      "A quiet and cozy place to stay in for a short getaway weekend with friends & family. A very friendly, kind and responsive host, Pri. Definitely will recommend to everyone and will come back again soon. A+ for this place and host💪🏼",
    guestName: "Nurafira Azni",
  },
  {
    image: reviewer3,
    rating: "5",
    review:
      "Its because my wedding the day before sangat senang untuk check in and out, easy parking, sangat selesa and yang penting muat untuk 5-6 pax. Sangat membantu bila chat sangat cepat dia balas. You guys kene stay dengan sini",
    guestName: "Nur",
  },
  {
    image: reviewer4,
    rating: "5",
    review:
      "The best thing was since it can fit 4 or 5 people having 2 full bathrooms was a big thing. The place was nice, played pool a little bit. The balcony was great. It had several chairs and sofa that we could pick and choose where we wanted to seat.",
    guestName: "Edlina",
  },
  {
    image: reviewer5,
    rating: "5",
    review: "Great place to chill with your friends",
    guestName: "Eidin",
  },
  {
    image: reviewer6,
    rating: "5",
    review:
      "Nice and cozy place. Very comfortable, clean and well kept. We felt right at home and felt so comfortable. Thank you so much",
    guestName: "Vishal",
  },
  {
    image: reviewer7,
    rating: "5",
    review:
      "Pri’s house really a good place to chill out during holidays, really want to book again for next vacation",
    guestName: "Arvin",
  },
  {
    image: reviewer8,
    rating: "5",
    review:
      "We booked Pri's place to watch the Euro Final. We enjoy playing pool & PS4 while waiting for the game. The place has 2 balconies, perfect for people who enjoy free time with friends.",
    guestName: "Zakie",
  },
  {
    image: reviewer9,
    rating: "5",
    review:
      "Very great place to hang out or chill with friends. We booked for small gathering bachelor party for my friend’s wedding. The beds cozy. Good entertainment since it has pool table and PS4 (need additional payment of RM50). Great balcony too. Really recommended to stay if you want to find a place to hang out with friends. Thanks Pri for the hosting! :)",
    guestName: "Mohd Amirul Faiz",
  },
  {
    image: reviewer10,
    rating: "5",
    review:
      "it was a great stay.the host really friendly.the place was beautiful.",
    guestName: "Naim",
  },
];

export const placesAroundList_en = [
  {
    place: "Secret Garden",
    image: secret_garden,
    mobileImage: secret_garden_mobile,
    description:
      "Discover a tranquil rooftop garden at 1 Utama Shopping Mall, featuring a diverse collection of plants and flowers. It’s a perfect spot for individuals or couples to have a peaceful escape amidst nature. ",
  },
  {
    place: "FlowRider",
    image: flowrider,
    mobileImage: flowrider_mobile,
    description:
      "Ever thought of surfing in a shopping mall? Now you can with the flowriding and surfing simulator in 1 Utama Shopping Centre. FlowRider is a hybrid boardsport combining the techniques of surfing, snowboarding, skateboarding, wakeboarding and skimboarding.",
  },
  {
    place: "VR Funn",
    image: vrfunn,
    mobileImage: vrfunn_mobile,
    description:
      "Enjoy thrilling solo or group immersive VR experiences 1 Utama Shopping Mall. They specialize in interactive shooting games, including zombie survival and competitive first-person shooters. An amazing way to spend time with your group or even for couples who are up a gaming date.",
  },
  {
    place: "Camp5 Climbing Gym",
    image: camp,
    mobileImage: camp_mobile,
    description:
      "Located in 1 Utama Shopping Mall, this indoor rock climbing facility caters to all skill levels. It’s a great way to stay active and have fun with friends or families.",
  },
  {
    place: "KidZania Kuala Lumpur",
    image: kidzania,
    mobileImage: kidzania_mobile,
    description:
      "An interactive edutainment center where kids can role-play various professions in a kid-sized city. Perfect for families looking for a fun, unique and educational experience with their kids",
  },
  {
    place: "Breakout",
    image: breakout,
    mobileImage: breakout_mobile,
    description:
      "Breakout Spy Game is the new game concept where your deam of becoming the next James Bond will become reality! Hide, Sneak and Don't Get Caught by the Guards to stealthily complete your missions. It's not an Escape Game, by the way! It's really little to No puzzles, but more obstacles and tasks to challenge you and your friends",
  },
  {
    place: "Game On Sports Hub",
    image: game_on,
    mobileImage: game_on_mobile,
    description:
      "Game On Sport Hub is located in 1 Utama Shopping Mall, where the excitement never stops, and the fun is always just a play away. Whether you're seeking adventure, relaxation, or the thrill of competition, they've got it all under one roof. Each attraction is designed to offer an unforgettable experience and endless fun for groups of all ages",
  },
  {
    place: "Morac Go Kart",
    image: go_kart,
    mobileImage: go_kart_mobile,
    description:
      "Whether you’re trying to beat your time or have fun as a group, Morac Go Kart Track in 1 Utama Shopping Mall is one of the most exciting new tracks in Malaysia. Perfect for groups looking for an adrenaline-pumping activity.",
  },
  {
    place: "NextGen",
    image: nextgen,
    mobileImage: nextgen_mobile,
    description:
      "NextGen Theme Park at 1 Utama Shopping Centre is Malaysia’s largest indoor edutainment theme park, spanning over 45,000 sqft. Recognized by the Malaysian Book of Records, it offers a variety of fun and educational activities suitable for all ages. The park features five exciting zones, including wall climbing, obstacle courses, and the Ninja Challenge Arena1",
  },
  {
    place: "WINDLAB Indoor Skydiving",
    image: air_rider_indoor_sky_diving,
    mobileImage: air_rider_indoor_sky_diving_mobile,
    description:
      "Experience the sensation of free-fall in a safe and controlled environment at WINDLAB Indoor Skydiving. Perfect for thrill-seekers.",
  },
  {
    place: "Treetop Adventure",
    image: treetop_adventure,
    mobileImage: treetop_adventure_mobile,
    description:
      "An outdoor adventure park with treetop obstacle courses and zip lines in 1 Utama Shopping Mall. Great for those looking to challenge themselves and enjoy nature.",
  },
  {
    place: "Tropicana Golf Resort",
    image: tropicana_golf,
    mobileImage: tropicana_golf_mobile,
    description:
      "A luxurious golf course and country club offering top-notch facilities and a relaxing environment. Perfect for golf enthusiasts.",
  },
  {
    place: "Community Forest Reserve ",
    image: kota_damansara_forest,
    mobileImage: kota_damansara_forest_mobile,
    description:
      "A beautiful forest reserve with well-maintained trails for hiking, walking, and bird watching. Ideal for nature lovers.",
  },
  {
    place: "Desa ParkCity",
    image: desa_park_city,
    mobileImage: desa_park_city_mobile,
    description:
      "A family-friendly park with a lake, playgrounds, and cafes. Perfect for a relaxing day out with friends, family or a nature date with your partner.",
  },
  {
    place: "IKEA",
    image: ikea,
    mobileImage: ikea_mobile,
    description:
      "A popular shopping destination for home furnishings and decor. Explore the showroom for inspiration and enjoy a meal at the IKEA restaurant.",
  },
  {
    place: "Restaurants & Pubs",
    image: restaurants,
    mobileImage: restaurants_mobile,
    description:
      "Enjoy the local fun and exciting night live around TTDI and Bandar Utama area. Lots of restaurants, for a simple meal or even fancy restuarants and bars for a date night or even a night with friends and colleagues",
  },
];
export const placesAroundList_bm = [
  {
    place: "Secret Garden",
    image: secret_garden,
    mobileImage: secret_garden_mobile,
    description:
      "Luangkan masa dalam taman atas bumbung yang tenang di 1 Utama Shopping Mall, menampilkan pelbagai koleksi tumbuhan dan bunga. Ia adalah tempat yang sempurna untuk individu atau pasangan yang ingin escape realiti dan bertenang di tengah-tengah alam semula jadi.",
  },
  {
    place: "FlowRider",
    image: flowrider,
    mobileImage: flowrider_mobile,
    description:
      "Pernah terfikir untuk meluncur di pusat membeli-belah? Sekarang anda boleh dengan simulator meluncur dan flowriding di 1 Utama Shopping Centre. FlowRider adalah sukan papan hibrid yang menggabungkan teknik meluncur, snowboarding, skateboarding, wakeboarding dan skimboarding.",
  },
  {
    place: "VR Funn",
    image: vrfunn,
    mobileImage: vrfunn_mobile,
    description:
      "Nikmati pengalaman VR yang mendalam secara solo atau berkumpulan di 1 Utama Shopping Mall. Mereka pakar dalam permainan menembak interaktif, termasuk survival zombie dan first player shooter yang kompetitif. Cara yang hebat untuk menghabiskan masa dengan kumpulan anda atau bahkan untuk pasangan yang ingin berkencan dengan permainan.",
  },
  {
    place: "Camp5 Climbing Gym",
    image: camp,
    mobileImage: camp_mobile,
    description:
      "Terletak di 1 Utama Shopping Mall, kemudahan rock climbing dalaman ini sesuai untuk semua peringkat kemahiran. Ia adalah cara yang baik untuk kekal aktif dan berseronok dengan kawan-kawan atau keluarga.",
  },
  {
    place: "KidZania Kuala Lumpur",
    image: kidzania,
    mobileImage: kidzania_mobile,
    description:
      "Pusat edutainment interaktif di mana kanak-kanak boleh bermain peranan pelbagai profesion dalam bandar bersaiz kanak-kanak. Sempurna untuk keluarga yang mencari pengalaman yang menyeronokkan, unik dan mendidik bersama anak-anak mereka.",
  },
  {
    place: "Breakout",
    image: breakout,
    mobileImage: breakout_mobile,
    description:
      "Breakout Spy Game adalah konsep permainan baru di mana impian anda untuk menjadi James Bond akan menjadi kenyataan! Sembunyi, menyelinap dan jangan ditangkap oleh pengawal untuk menyelesaikan misi anda dengan licik. Ia bukan permainan escape room, ya! Ia hanya mempunyai sedikit atau tiada teka-teki, tetapi lebih banyak halangan dan tugas untuk mencabar anda dan kawan-kawan anda.",
  },
  {
    place: "Game On Sports Hub",
    image: game_on,
    mobileImage: game_on_mobile,
    description:
      "Game On Sport Hub terletak di 1 Utama Shopping Mall, di mana keseronokannya tidak pernah berhenti. Sama ada anda mencari pengembaraan, bersantai, atau keseronokan persaingan, mereka ada semuanya di bawah satu bumbung. Setiap tarikan direka untuk menawarkan pengalaman yang tidak dapat dilupakan dan keseronokan tanpa henti untuk kumpulan dari semua peringkat umur.",
  },
  {
    place: "Morac Go Kart",
    image: go_kart,
    mobileImage: go_kart_mobile,
    description:
      "Sama ada anda ingin luangkan masa anda atau berseronok sebagai satu kumpulan, Morac Go Kart Track di 1 Utama Shopping Mall adalah salah satu trek baru yang paling menarik di Malaysia. Sempurna untuk kumpulan yang mencari aktiviti yang memacu adrenalin.",
  },
  {
    place: "NextGen",
    image: nextgen,
    mobileImage: nextgen_mobile,
    description:
      "NextGen Theme Park di 1 Utama Shopping Centre adalah taman tema edutainment dalaman terbesar di Malaysia, meliputi lebih 45,000 kaki persegi. Diiktiraf oleh Malaysian Book of Records, ia menawarkan pelbagai aktiviti yang menyeronokkan dan mendidik yang sesuai untuk semua peringkat umur. Taman ini mempunyai lima zon yang menarik, termasuk panjat dinding, kursus halangan, dan Ninja Challenge Arena.",
  },
  {
    place: "WINDLAB Indoor Skydiving",
    image: air_rider_indoor_sky_diving,
    mobileImage: air_rider_indoor_sky_diving_mobile,
    description:
      "Rasai sensasi jatuh bebas dalam persekitaran yang selamat dan terkawal di WINDLAB Indoor Skydiving. Sempurna untuk pencari keseronokan.",
  },
  {
    place: "Treetop Adventure",
    image: treetop_adventure,
    mobileImage: treetop_adventure_mobile,
    description:
      "Taman pengembaraan luar dengan kursus halangan di atas pokok dan zip line di 1 Utama Shopping Mall. Hebat untuk mereka yang ingin mencabar diri dan menikmati alam semula jadi.",
  },
  {
    place: "Tropicana Golf Resort",
    image: tropicana_golf,
    mobileImage: tropicana_golf_mobile,
    description:
      "Padang golf dan kelab negara yang mewah menawarkan kemudahan terbaik dan persekitaran yang santai. Sempurna untuk peminat golf.",
  },
  {
    place: "Community Forest Reserve ",
    image: kota_damansara_forest,
    mobileImage: kota_damansara_forest_mobile,
    description:
      "Hutan simpan yang indah dengan laluan yang terjaga untuk mendaki, berjalan, dan memerhati burung. Sesuai untuk pencinta alam semula jadi.",
  },
  {
    place: "Desa ParkCity",
    image: desa_park_city,
    mobileImage: desa_park_city_mobile,
    description:
      "Taman mesra keluarga dengan tasik, taman permainan, dan kafe. Sempurna untuk bersantai bersama kawan-kawan, keluarga atau janji temu alam semula jadi dengan pasangan anda.",
  },
  {
    place: "IKEA",
    image: ikea,
    mobileImage: ikea_mobile,
    description:
      "Destinasi membeli-belah popular untuk perabot dan hiasan rumah. Terokai bilik pameran untuk inspirasi dan nikmati hidangan di restoran IKEA.",
  },
  {
    place: "Restaurants & Pubs",
    image: restaurants,
    mobileImage: restaurants_mobile,
    description:
      "Nikmati keseronokan tempatan dan kehidupan malam yang menarik di sekitar kawasan TTDI dan Bandar Utama. Banyak restoran, untuk hidangan ringkas atau restoran mewah untuk malam date atau malam bersama kawan-kawan dan rakan sekerja.",
  },
];
