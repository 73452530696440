import { Flex, Image, Text, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import logoImage from "./../images/logoImage.png";
import logoGif from "./../images/logoGif.gif";
import superhostGif from "./../images/superhostGif.gif";
import guestFavGif from "./../images/guestFavGif.gif";
import {
  theme_color_red,
  theme_color_white,
  theme_color_blue_light,
  topNav_font_size_mobile,
  topNav_font_size_narrow,
  topNav_font_size_wide,
  topNav_padding_mobile,
  topNav_padding_narrow,
  topNav_padding_wide,
  theme_color_black,
} from "../../const";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { changeLanguage } from "../redux/languageSlice";
import { useAppDispatch } from "../../store";

export const TopNavBar = ({
  featureRef,
  reviewRef,
  locationRef,
  contactUsRef,
  homeRef,
}) => {
  const dispatch = useAppDispatch();
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const isReviewVisible = useSelector((state) => state.ReviewVisible.isVisible);
  const languageState = useSelector((state) => state.Language.language);
  const [fontSize, setFontSize] = useState(topNav_font_size_wide);
  const [padding, setPadding] = useState(topNav_padding_wide);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [isEnglish, setIsEnglish] = useState(languageState === "English");
  useEffect(() => {
    if (deviceType === "wide") {
      setFontSize(topNav_font_size_wide);
      setPadding(topNav_padding_wide);
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setFontSize(topNav_font_size_narrow);
      setPadding(topNav_padding_narrow);
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setFontSize(topNav_font_size_mobile);
      setPadding(topNav_padding_mobile);
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    setIsEnglish(languageState === "English");
  }, [languageState]);

  return (
    <Flex flexDir={"column"} w={"100%"} h={"100%"}>
      <Flex
        flexDir={"row"}
        w={"100%"}
        h={isMobile ? "15%" : "12%"}
        bgColor={theme_color_white}
        pl={isMobile ? "5px" : "50px"}
        pr={isMobile ? "5px" : "20px"}
        align={"center"}
        justifyContent={isMobile ? "space-around" : "space-between"}
        position="fixed"
        top="0"
        left="0"
        zIndex="999"
      >
        {isMobile && (
          <Menu >
            <MenuButton as={IconButton} icon={<HamburgerIcon />} />
            <MenuList>
              <MenuItem onClick={() => featureRef.current?.scrollIntoView({ behavior: "smooth" })}>
                {languageState === "English" ? "Features" : "Ciri-ciri"}
              </MenuItem>
              <MenuItem onClick={() => reviewRef.current?.scrollIntoView({ behavior: "smooth" })}>
                {languageState === "English" ? "Reviews" : "Ulasan"}
              </MenuItem>
              <MenuItem onClick={() => locationRef.current?.scrollIntoView({ behavior: "smooth" })}>
                {languageState === "English" ? "Location" : "Lokasi"}
              </MenuItem>
              <MenuItem onClick={() => contactUsRef.current?.scrollIntoView({ behavior: "smooth" })}>
                {languageState === "English" ? "Contact Us" : "Hubungi Kami"}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        <Image
          w={"200px"}
          h={"60px"}
          src={logoGif}
          cursor={"pointer"}
          onClick={(e) => {
            e.preventDefault();
            homeRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
        {!isMobile && isReviewVisible && (
          <>
            <Image
              pl={"15px"}
              w={"180px"}
              h={"50px"}
              src={superhostGif}
              cursor={"pointer"}
              onClick={(e) => {
                e.preventDefault();
                homeRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
            <Image
              pl={"15px"}
              w={"180px"}
              h={"60px"}
              src={guestFavGif}
              cursor={"pointer"}
              onClick={(e) => {
                e.preventDefault();
                homeRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
          </>
        )}
        {!isMobile && (
          <>
            <Flex align={"end"} pr={"20px"}>
              <Text
                textAlign={"center"}
                fontSize={fontSize}
                color={theme_color_black}
                pl={padding}
                pr={padding}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  featureRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {languageState === "English" ? "Features" : "Ciri-ciri"}
              </Text>

              <Text
                textAlign={"center"}
                fontSize={fontSize}
                color={theme_color_black}
                pl={padding}
                pr={padding}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  reviewRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {languageState === "English" ? "Reviews" : "Ulasan"}
              </Text>
              <Text
                textAlign={"center"}
                fontSize={fontSize}
                color={theme_color_black}
                pl={padding}
                pr={padding}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  locationRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {languageState === "English" ? "Location" : "Lokasi"}
              </Text>
              <Text
                textAlign={"center"}
                fontSize={fontSize}
                color={theme_color_black}
                pl={padding}
                pr={padding}
                cursor={"pointer"}
                onClick={(e) => {
                  e.preventDefault();
                  contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {languageState === "English" ? "Contact Us" : "Hubungi Kami"}
              </Text>
              <Flex flexDir={"row"} alignItems={"center"} justifyContent={"center"}>
                <Flex
                  cursor={"pointer"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderLeftRadius={isMobile ? "0px" : "10px"}
                  borderTopRadius={isMobile ? "10px" : null}
                  borderColor={theme_color_black}
                  borderWidth={isEnglish ? "2px" : "2px"}
                  fontSize={"12px"}
                  color={isEnglish ? theme_color_black : theme_color_white}
                  boxShadow="md"
                  bgColor={isEnglish ? theme_color_white : theme_color_black}
                  pl={"10px"}
                  pr={"11px"}
                  pt={"2px"}
                  pb={"2px"}
                  onClick={() => dispatch(changeLanguage("English"))}
                >
                  EN
                </Flex>
                <Flex
                  cursor={"pointer"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRightRadius={isMobile ? "0px" : "10px"}
                  borderBottomRadius={isMobile ? "10px" : null}
                  borderColor={theme_color_black}
                  borderWidth={!isEnglish ? "1px" : "1px"}
                  fontSize={"12px"}
                  color={!isEnglish ? theme_color_black : theme_color_white}
                  boxShadow="md"
                  bgColor={!isEnglish ? theme_color_white : theme_color_black}
                  pl={"10px"}
                  pr={"10px"}
                  pt={"2px"}
                  pb={"2px"}
                  onClick={() => dispatch(changeLanguage("Malay"))}
                >
                  BM
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
        {isMobile && (
          <Flex flexDir={"column"} alignItems={"center"} justifyContent={"center"}>
            <Flex
              cursor={"pointer"}
              alignItems={"center"}
              justifyContent={"center"}
              borderLeftRadius={isMobile ? "0px" : "10px"}
              borderTopRadius={isMobile ? "10px" : null}
              borderColor={theme_color_black}
              borderWidth={isEnglish ? "2px" : "2px"}
              fontSize={"12px"}
              color={isEnglish ? theme_color_black : theme_color_white}
              boxShadow="md"
              bgColor={isEnglish ? theme_color_white : theme_color_black}
              pl={"10px"}
              pr={"11px"}
              pt={"2px"}
              pb={"2px"}
              onClick={() => dispatch(changeLanguage("English"))}
            >
              EN
            </Flex>
            <Flex
              cursor={"pointer"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRightRadius={isMobile ? "0px" : "10px"}
              borderBottomRadius={isMobile ? "10px" : null}
              borderColor={theme_color_black}
              borderWidth={!isEnglish ? "1px" : "1px"}
              fontSize={"12px"}
              color={!isEnglish ? theme_color_black : theme_color_white}
              boxShadow="md"
              bgColor={!isEnglish ? theme_color_white : theme_color_black}
              pl={"10px"}
              pr={"10px"}
              pt={"2px"}
              pb={"2px"}
              onClick={() => dispatch(changeLanguage("Malay"))}
            >
              BM
            </Flex>
          </Flex>)}
      </Flex>
      <Flex w={"100%"} h={"100%"}>
        <Outlet />
      </Flex>
    </Flex>
  );
};
